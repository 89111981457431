// src/pages/Favorites.js
import React from 'react';
import FavoriteProducts from '../components/FavoriteProducts';

const Favorites = () => {
    return <div>Favorites Page


        <FavoriteProducts></FavoriteProducts>
    </div>;
};

export default Favorites;
