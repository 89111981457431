// Footer.js

import React from "react";
import { Link } from "react-router-dom";
import '../Styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <h4>Section 1</h4>
          <Link to="/">Home</Link>
          <Link to="/AllProducts">All Products</Link>
          <Link to="/favorites">Favorites</Link>
          <Link to="/cart">Cart</Link>
        </div>
        <div className="footer-links">
          <h4>Section 2</h4>
          <Link to="/account">Account</Link>
          <Link to="/products">Products</Link>
          <Link to="/categories">Categories</Link>
          <Link to="/login">Login</Link>
        </div>
        <div className="footer-links">
          <h4>Section 3</h4>
          <Link to="/">Home</Link>
          <Link to="/AllProducts">All Products</Link>
          <Link to="/favorites">Favorites</Link>
          <Link to="/cart">Cart</Link>
        </div>
      </div>
      <p className="footer-text">© 2024 Your Company Name. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
