import React from 'react';
import '../Styles/Skeleton.css';

const Skeleton = () => {
  return (
    <div className="skeleton">
      <div className="skeleton-content">
        <div className="skeleton-line animated"></div>
        <div className="skeleton-line animated"></div>
        <div className="skeleton-line animated"></div>
        <div className="skeleton-line animated"></div>
        <div className="skeleton-line animated"></div>
      </div>
     
    </div>
  );
};

export default Skeleton;