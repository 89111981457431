// src/pages/Home.js
import React from "react";
import Products from "../components/Products";
import Breadcrumb from "../components/Breadcrumb";
import Categories from "../components/Categories";
import Slider from "../components/Slider";
import "../Styles/home.css";
import Chat from "../components/ChatComponent";

const Home = () => {
  // return ;
  return (
    <div className="Home">
      <div className="Slider-section">
        <Slider></Slider>
      </div>

      {/* <Breadcrumb />  */}
      <div className="Categories-section">
        <Categories />
      </div>

      <Products></Products>
      <Chat></Chat>
    </div>
  );
};

export default Home;
