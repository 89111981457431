// ErrorPage.js
import React from "react";
import "../Styles/ErrorPage.css"; // Import CSS file for ErrorPage styling

import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  const Home = () => {
    navigate("/");
  };

  return (
    <div className="error-container">
      <div className="error-content">
        <h1>404 Not Found</h1>
        <p>The page you're looking for doesn't exist.</p>

        <button className="Goto-home-btn" onClick={Home}>
          Go To Home
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
