import React, { useState } from 'react';
import { FaHome, FaBook, FaHeart, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../Styles/BottomTabs.css'; // Import the stylesheet

const BottomTabs = () => {
    const [activeTab, setActiveTab] = useState('/');

    const handleTabClick = (tabPath) => {
        setActiveTab(tabPath);
    };

    return (
        <div className="bottom-tabs-container">
            <Link
                to="/"
                className={`bottom-tab ${activeTab === '/' ? 'active' : ''}`}
                onClick={() => handleTabClick('/')}
            >
                <FaHome className="icon" />
                Home
            </Link>
            <Link
                to="/AllProducts"
                className={`bottom-tab ${activeTab === '/AllProducts' ? 'active' : ''}`}
                onClick={() => handleTabClick('/AllProducts')}
            >
                <FaBook className="icon" />
                Products
            </Link>
            <Link
                to="/categories"
                className={`bottom-tab ${activeTab === '/categories' ? 'active' : ''}`}
                onClick={() => handleTabClick('/categories')}
            >
                <FaHeart className="icon" />
                Categories
            </Link>
            <Link
                to="/account"
                className={`bottom-tab ${activeTab === '/account' ? 'active' : ''}`}
                onClick={() => handleTabClick('/account')}
            >
                <FaUser className="icon" />
                Account
            </Link>
        </div>
    );
};

export default BottomTabs;
