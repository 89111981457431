import React from "react";
import PropTypes from "prop-types";


const Cart = ({ cartItems, totalItems, totalPrice }) => {
  return (
    <div style={styles.cartContainer}>
      <h2 style={styles.cartTitle}>Your Shopping Cart</h2>
      
      
    </div>
  );
};

Cart.propTypes = {
  cartItems: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

const styles = {
  cartContainer: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  cartTitle: {
    textAlign: "center",
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  cartItems: {
    marginBottom: "20px",
  },
  cartSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #ccc",
    paddingTop: "10px",
  },
  checkoutButton: {
    padding: "10px 20px",
    fontSize: "1rem",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
    borderRadius: "5px",
  },
};

export default Cart;
