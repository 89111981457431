import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import LoadingSpinner from "./LoadingSpinner";
import ProductsNotFound from "./ProductsNotFound";

const ByCategory = () => {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productNotFound, setProductNotFound] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategoryName(categoryId);
    fetchProductsByCategory(categoryId);
  }, [categoryId]);

  const handleAddToFavorites = async (productId) => {
    try {
      // Retrieve user_id from localStorage
      const userId = localStorage.getItem("userId");

      // Check if userId exists in localStorage
      if (!userId) {
        console.log("User not logged in");
        // Redirect to login page or handle authentication as needed
        navigate("/login"); // Example redirect to login page
        return;
      }

      // Send POST request to add product to favorites
      const response = await axios.post(
        `https://ecommerce-api-m8il.vercel.app/favorite/${userId}?product_id=${productId}`
      );

      console.log("Product added to favorites:", response.data);
      alert("Product added to favorites!");
    } catch (error) {
      console.error("Error adding product to favorites:", error);
      alert("Failed to add product to favorites. Please try again.");
    }
  };

  const fetchCategoryName = async (categoryId) => {
    try {
      const response = await axios.get(
        `https://ecommerce-api-m8il.vercel.app/categories/${categoryId}`
      );
      setCategoryName(response.data.name); // Assuming the category API returns an object with a 'name' field
    } catch (error) {
      console.error("Error fetching category name:", error);
    }
  };

  const fetchProductsByCategory = async (categoryId) => {
    try {
      const response = await axios.get(
        `https://ecommerce-api-m8il.vercel.app/products/by-category/${categoryId}`
      );
      setProducts(response.data);
      setProductNotFound(false);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setProductNotFound(true);
      } else {
        console.error("Error fetching products:", error);
      }
      setLoading(false);
    }
  };

  const AllProducts = () => {
    navigate("/AllProducts");
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (productNotFound) {
    return <ProductsNotFound />;
  }

  const handleBuyNow = (productId) => {
    navigate(`/SingleProduct/${productId}`);
  };

  return (
    <div style={styles.productContainer}>
      <div className="container-head">
        <h2>Products in Category: {categoryName}</h2>
        <button className="AllProducts" onClick={AllProducts}>
          All Products
        </button>
      </div>

      <div style={styles.productGrid}>
        {products.map((product) => (
          <div key={product.product_id} style={styles.productCard}>
            <div style={styles.productImageContainer}>
              <img
                src={product.product_picture}
                alt={product.name}
                style={styles.productImage}
              />
              <div style={styles.actionButtons}>
                <button
                  style={styles.likeButton}
                  onClick={() => handleAddToFavorites(product.product_id)}
                >
                  <FaHeart size={20} />
                </button>
              </div>
            </div>
            <div style={styles.productInfo}>
              <p style={styles.productName}>{product.name}</p>
              <p style={styles.productDescription}>{product.description}</p>
              <p style={styles.productPrice}>${product.price}</p>
            </div>
            <button
              onClick={() => handleBuyNow(product.product_id)}
              style={styles.buyNowButton}
            >
              Buy Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  productContainer: {
    padding: "20px",
    backgroundColor: "#c0c0c0",
    borderRadius: "10px",
  },
  productGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "20px",
  },
  productCard: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  productImageContainer: {
    position: "relative",
  },
  productImage: {
    width: "100%",
    height: "300px",
    display: "block",
  },
  actionButtons: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  likeButton: {
    backgroundColor: "#dc3545",
    color: "#ffffff",
    border: "none",
    padding: "8px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  productInfo: {
    padding: "10px",
  },
  productName: {
    fontSize: "1.2em",
    fontWeight: "bold",
    margin: "0",
  },
  productDescription: {
    fontSize: "1em",
    color: "#666666",
    margin: "5px 0",
  },
  productPrice: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: "#007bff",
    margin: "0",
  },
  buyNowButton: {
    marginTop: "10px",
    padding: "10px 20px",
    fontSize: "1em",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#f88b2a",
    color: "#fff",
    borderRadius: "1px",
    width: "100%",
  },
};

export default ByCategory;
