import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Chat = () => {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSendMessage = async () => {
    // Prepare the email content
    const emailContent = {
      to: "masadnazir1@gmail.com", // Replace with your hardcoded email address
      subject: "Ecommerce Digihut", // Replace with your hardcoded subject
      text: message, // Message from textarea
    };

    // Send email via API
    try {
      const response = await fetch("https://api.emailsend.digihut.store/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailContent),
      });

      if (response.ok) {
        console.log("Email sent successfully");
        // Show success toast notification
        toast.success("Email sent successfully!", {
          position: "top-right"
        });

        // Optionally: Reset message state or close chat popup
        setMessage("");
        setIsOpen(false);
      } else {
        console.error("Failed to send email:", response.statusText);
        // Show error toast notification
        toast.error("Failed to send email. Please try again later.", {
          position: "top-right"
        });
      }
    } catch (error) {
      console.error("Error sending email:", error.message);
      // Show error toast notification
      toast.error("Error sending email. Please try again later.", {
        position: "top-right"
      });
    }
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const chatButtonStyle = {
    position: "fixed",
    bottom: "90px",
    right: "0px",
    backgroundColor: "#0084ff",
    color: "white",
    padding: "10px 20px",
    borderRadius: "0px",
    cursor: "pointer",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s",
  };

  const chatButtonHoverStyle = {
    backgroundColor: "#005bb5",
  };

  const chatPopupStyle = {
    position: "fixed",
    bottom: "22%",
    right: "0%",
    width: "20%",
    backgroundColor: "#f85606",
    
    borderRadius: "3px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    padding: "10px",
  };

  const buttonStyle = {
    backgroundColor: "#000",
    color: "white",
    padding: "10px",
    border: "none",
    width: "100%",
    borderRadius: "2px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  const input = {
    width: "90%",
    height: "100px",
    marginBottom: "10px",
    border: "none",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.5)", // Inner shadow effect
    outline: "none",
    borderRadius: "10px",
  };

  const buttonHoverStyle = {
    backgroundColor: "#005bb5",
  };

  return (
    <div>
      <div
        className="chat-button"
        onClick={toggleChat}
        style={chatButtonStyle}
        onMouseEnter={(e) =>
          (e.target.style.backgroundColor =
            chatButtonHoverStyle.backgroundColor)
        }
        onMouseLeave={(e) =>
          (e.target.style.backgroundColor = chatButtonStyle.backgroundColor)
        }
      >
        Chat
      </div>
      {isOpen && (
        <div className="chat-popup" style={chatPopupStyle}>
          <h2>Get instant Help</h2>
          <textarea
            placeholder="Type your message..."
            style={input}
            value={message}
            onChange={handleChangeMessage}
          ></textarea>
          <button
            onClick={handleSendMessage}
            style={buttonStyle}
            onMouseEnter={(e) =>
              (e.target.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onMouseLeave={(e) =>
              (e.target.style.backgroundColor = buttonStyle.backgroundColor)
            }
          >
            Send
          </button>
        </div>
      )}

      {/* Toast Container for notifications */}
      <ToastContainer />
    </div>
  );
};

export default Chat;
