import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [selectedUserInfo, setSelectedUserInfo] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://ecommerce-api-m8il.vercel.app/users');
        setUsers(response.data);
        setSelectedUser(response.data[0]?.user_id || ''); // Select the first user by default
        setSelectedUserInfo(response.data[0] || {}); // Set selected user info for header
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem('userId');
    if (userIdFromStorage) {
      setCurrentUser(userIdFromStorage);
      setSelectedUser(userIdFromStorage); // Set the selected user initially
      const userInfo = users.find(user => user.user_id === userIdFromStorage);
      setSelectedUserInfo(userInfo || {});
    }
  }, [users]);

  useEffect(() => {
    if (selectedUser) {
      fetchMessages(selectedUser);
    }
  }, [selectedUser]);

  const fetchMessages = async (userId) => {
    try {
      const response = await axios.get(`https://ecommerce-api-m8il.vercel.app/messages?user_id=${userId}`);
      setMessages(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!selectedUser || !newMessage.trim()) {
      return;
    }

    try {
      const response = await axios.post('https://ecommerce-api-m8il.vercel.app/messages', null, {
        params: { user_id: selectedUser, message: newMessage },
      });
      setMessages([...messages, response.data]);
      setNewMessage('');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUserChange = (userId) => {
    setSelectedUser(userId);
    const userInfo = users.find(user => user.user_id === userId);
    setSelectedUserInfo(userInfo || {});
  };

  if (loading) return <p>Loading messages...</p>;
  if (error) return <p>Error fetching messages: {error}</p>;

  return (
    <div style={styles.container}>
      <h2 style={styles.header}> </h2>
        {selectedUserInfo.username} 
        
        <h2 style={styles.email}>
        ({selectedUserInfo.email})
      </h2>
      <div style={styles.messagesContainer}>
        {messages.length === 0 ? (
          <p>No messages found.</p>
        ) : (
          <ul style={styles.messagesList}>
            {messages.map((message) => (
              <li
                key={message.id}
                style={
                  message.user_id === currentUser
                    ? { ...styles.messageItem, ...styles.myMessage }
                    : styles.messageItem
                }
              >
                <div
                  style={
                    message.user_id === currentUser
                      ? { ...styles.messageBubble, ...styles.myMessageBubble }
                      : styles.messageBubble
                  }
                >
                  <p style={styles.messageText}>{message.message}</p>
                  <small style={styles.timestamp}>
                    {new Date(message.timestamp).toLocaleString()}
                  </small>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div style={styles.inputContainer}>
        <select
          value={selectedUser}
          onChange={(e) => handleUserChange(e.target.value)}
          style={styles.select}
        >
          {users.map((user) => (
            <option key={user.user_id} value={user.user_id}>
              {user.username} ({user.email})
            </option>
          ))}
        </select>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          style={styles.input}
          placeholder="Type a message..."
        />
        <button onClick={handleSendMessage} style={styles.button}>
          Send
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    background: '#f0f0f0',
  },
  header: {
    textAlign: 'center',
    marginBottom: '10px',
    fontSize: '25px',
    color: '#333',
  },
  email: {
    textAlign: 'center',
    marginBottom: '10px',
   fontSize: '10px',
    color: '#333',
  },


  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: '10px',
  },
  messagesList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  messageItem: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  messageBubble: {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    background: '#ffffff',
  },
  myMessage: {
    alignSelf: 'flex-end',
  },
  myMessageBubble: {
    alignSelf: 'flex-end',
    background: '#DCF8C6',
  },
  messageText: {
    margin: 0,
    fontSize: '16px',
    color: '#333',
  },
  timestamp: {
    fontSize: '12px',
    color: '#888',
    marginTop: '4px',
    textAlign: 'right',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #ddd',
    padding: '10px',
    background: '#ffffff',
  },
  select: {
    padding: '8px',
    fontSize: '16px',
    marginRight: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  input: {
    flex: 1,
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginRight: '10px',
    outline: 'none',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
};

export default Messages;
