import React from "react";
import "../Styles/Navbar.css"; // Import the CSS file for styling
import logo from "../Icons/Logo.png"; // Replace with your logo image
import searchIcon from "../Icons/search-icon.png"; // Replace with your search icon image
import cartIcon from "../Icons/cart-icon.png"; // Replace with your cart icon image
import Like from "../Icons/Like.png";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const navigateTologin = () => {
    navigate("/login");
  };

  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="search">
          <input type="text" placeholder="Type here that you're looking for" />
          <button type="submit" className="search-btn">
           Search
          </button>
        </div>
        <div className="icons">
          <Link to="/favorites">
            <img src={Like} alt="Favorites" className="icon" id="fav-icon" />
          </Link>
          <Link to="/cart">
            <img src={cartIcon} alt="Cart" className="icon" id="cart-icon" />
          </Link>
          <button className="loginbtn" onClick={() => navigateTologin()}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
