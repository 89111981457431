// src/Pages/CategoriesPage.js

import React, { useState } from 'react';
import Categories from '../components/Categories';
import ProductsByCategory from '../components/ProductsByCategory';

const CategoriesPage = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    console.log(categoryId);
  };

  return (
    <div>
      <Categories onCategoryClick={handleCategoryClick} />
      {selectedCategoryId && <ProductsByCategory categoryId={selectedCategoryId} />}
    </div>
  );
};

export default CategoriesPage;
