import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Styles/SingleProduct.css";
import LoadingSpinner from "./LoadingSpinner";

const SingleProduct = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [suggestedProducts, setSuggestedProducts] = useState([]);

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        `https://ecommerce-api-m8il.vercel.app/products/${productId}`
      );
      setProduct(response.data);
      fetchByCategory(response.data.category_id); // Fetch suggested products based on category
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const fetchByCategory = async (categoryId) => {
    try {
      const response = await axios.get(
        `https://ecommerce-api-m8il.vercel.app/products/by-category/${categoryId}`
      );
      setSuggestedProducts(response.data);
    } catch (error) {
      console.error("Error fetching suggested products:", error);
    }
  };

  const handleBuyNow = () => {
    navigate(`/checkout/${productId}`); // Pass productId in the URL
    // alert("Add to Cart clicked");
  };

  const handleAddToCart = () => {
    // Handle Add to Cart logic
    alert("Add to Cart clicked");
  };

  const navigateToProduct = (productId) => {
    navigate(`/SingleProduct/${productId}`);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!product) {
    return <LoadingSpinner />;
  }

  return (
    <div className="productDetail">
      <div className="basicDetails">
        <div className="productImageContainer">
          <img
            src={product.product_picture}
            alt={product.name}
            className="mainimage"
          />
        </div>
        <div className="productDetails">
          <div className="Pricecontainer">
            <h2 className="productName">{product.name}</h2>
            <p className="productDescription">{product.description}</p>
            <p className="productPrice">
              Price: ${parseFloat(product.price).toFixed(2)}
            </p>
          </div>
          <div className="buttonContainer">
            <button onClick={handleBuyNow} className="buyNowButton">
              Buy Now
            </button>
            <button onClick={handleAddToCart} className="addToCartButton">
              Add to Cart
            </button>
          </div>
        </div>
      </div>
      <div className="suggestedProducts">
        <h3>Suggested Products</h3>
        <div className="suggestedProductList">
          {suggestedProducts.map((product) => (
            <div key={product.product_id} className="suggestedProduct">
              <img
                src={product.product_picture}
                alt={product.name}
                className="suggestedProductImage"
              />
              <div className="suggestbtncontainer">
                <h4>{product.name}</h4>
                <p>Price: ${parseFloat(product.price).toFixed(2)}</p>
                <button
                  onClick={() => navigateToProduct(product.product_id)}
                  className="suggestedProductLink"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
