import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import ProductsNotFound from "./ProductsNotFound";
import "../Styles/FavoriteProducts.css"; // Import CSS file for component styling

const FavoriteProducts = () => {
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [productsDetails, setProductsDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if userId exists in localStorage
    const userId = localStorage.getItem("userId");
    if (!userId) {
      navigate("/login"); // Navigate to login page if userId is not present
      return;
    }

    const fetchFavoriteProducts = async () => {
      try {
        const response = await axios.get(
          `https://ecommerce-api-m8il.vercel.app/favorite?user_id=${userId}`
        );
        setFavoriteProducts(response.data);
        setLoading(false);
        // Once favorite products are fetched, fetch their details
        fetchProductsDetails(response.data);
      } catch (error) {
        setError("Error fetching favorite products. Please try again.");
        setLoading(false);
      }
    };

    fetchFavoriteProducts();
  }, [navigate]);

  const fetchProductsDetails = async (favoriteProductIds) => {
    try {
      const productsPromises = favoriteProductIds.map(async (product) => {
        const response = await axios.get(
          `https://ecommerce-api-m8il.vercel.app/products/${product.product_id}`
        );
        return response.data;
      });
      const productsData = await Promise.all(productsPromises);
      setProductsDetails(productsData);
    } catch (error) {
      setError("Error fetching product details. Please try again.");
    }
  };

  const handleBuyNow = (productId) => {
    navigate(`/SingleProduct/${productId}`);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ProductsNotFound message={error} />;
  }

  return (
    <div className="favorite-products-container">
      <h2>Favorite Products</h2>
      {productsDetails.length === 0 ? (
        <p>No favorite products found.</p>
      ) : (
        <div className="product-grid">
          {productsDetails.map((product) => (
            <div key={product.product_id} className="product-card">
              <img
                src={product.product_picture}
                alt={product.name}
                className="product-image"
              />
              <div className="product-details">
                <h3>{product.name}</h3>
                <p className="product-price">${product.price}</p>
                <button
                  onClick={() => handleBuyNow(product.product_id)}
                  className="buy-now-button"
                >
                  Buy Now
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FavoriteProducts;
