import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes,  } from "react-router-dom";
import Navbar from "./components/Navbar";
import BottomTabs from "./components/BottomTabs";
import Home from "./Pages/Home";
import AllProducts from './components/AllProducts';
import Checkout from './components/Checkout';
import Favorites from "./Pages/Favorites";
import Account from "./Pages/Account";
import Cart from "./components/cart";
import Products from "./components/Products";
import SingleProduct from "./components/Singleproduct";
import Chat from './components/ChatComponent';
import ByCategory from "./components/ByCategory";
import CategoriesPage from "./Pages/CategoriesPage";
import Login from "./components/Login";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";
import Messages from "./components/messages";
import "./App.css";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Example state for user authentication

  // Mock authenticateUser function for demonstration
  const authenticateUser = () => {
    setIsLoggedIn(false); // Simulate successful login
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem("userId"); // Clear user ID from localStorage
    setIsLoggedIn(false); // Update login state
  };

  return (
    <Router>
      <div className="container">
        <Navbar isLoggedIn={isLoggedIn} logout={logout} />
      
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login authenticateUser={authenticateUser} />} />
            <Route path="/favorites" element={<Favorites isLoggedIn={isLoggedIn} />} />
            <Route path="/cart" element={<Cart isLoggedIn={isLoggedIn} />} />
            <Route path="/favorites" element={<Favorites isLoggedIn={isLoggedIn} />} />
            <Route path="/AllProducts" element={<AllProducts isLoggedIn={isLoggedIn} />} />
            <Route path="/account" element={<Account isLoggedIn={isLoggedIn} />} />
            <Route path="/products" element={<Products isLoggedIn={isLoggedIn} />} />
            <Route path="/Messages" element={<Messages />} />
            <Route path="/SingleProduct/:productId" element={<SingleProduct isLoggedIn={isLoggedIn} />} />
           

            <Route path="/checkout/:productId" element={<Checkout />} />
            <Route path="/categories" element={<CategoriesPage isLoggedIn={isLoggedIn} />} />
            <Route path="/ByCategory/:categoryId" element={<ByCategory isLoggedIn={isLoggedIn} />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        <Chat></Chat>
        <Footer />
        <BottomTabs />
      </div>
    </Router>
  );
};

export default App;
