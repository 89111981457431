import React, { useEffect, useState } from "react";
import "../Styles/Slider.css"; // Import the CSS file for styling

const Slider = () => {
  const slides = [
    { image: "https://mobirise.com/extensions/commercem4/assets/images/3.jpg", alt: "Slide 1" },
    { image: "https://mobirise.com/extensions/commercem4/assets/images/gallery05.jpg", alt: "Slide 2" },
    { image: "https://mobirise.com/extensions/commercem4/assets/images/gallery05.jpg", alt: "Slide 3" }
    // Add more slides as needed
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      goToSlide(currentSlide + 1);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentSlide]);

  const goToSlide = (index) => {
    const totalSlides = slides.length;
    if (index < 0) {
      index = totalSlides - 1;
    } else if (index >= totalSlides) {
      index = 0;
    }
    setCurrentSlide(index);
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="slider-container">
      <div className="slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={slide.alt} />
          </div>
        ))}
      </div>
      <div className="slider-dots">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
