// src/components/ProductsByCategory.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner'; // Assuming you have a LoadingSpinner component
import '../Styles/ProductsByCategory.css'; // Create a CSS file for styles

const ProductsByCategory = ({ categoryId }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProductsByCategory(categoryId);
  }, [categoryId]);

  const fetchProductsByCategory = async (categoryId) => {
    try {
      const response = await axios.get(
        `https://ecommerce-api-m8il.vercel.app/products/by-category/${categoryId}`
      );

      console.log(categoryId);


      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="productsByCategory">
      {products.map(product => (
        <div key={product.product_id} className="productCard">
          <img src={product.product_picture} alt={product.name} className="productImage" />
          <h4 className="productName">{product.name}</h4>
          <p className="productPrice">${parseFloat(product.price).toFixed(2)}</p>
        </div>
      ))}
    </div>
  );
};

export default ProductsByCategory;
