import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Skelton from "./Skelton";

import "../Styles/Categories.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://ecommerce-api-m8il.vercel.app/categories"
      );
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  const navigateToProduct = (categoryId) => {
    navigate(`/ByCategory/${categoryId}`);
  };


  const Allcategories = () => {
    navigate('/categories');
  };







  if (loading) {
    return <Skelton />;
  }

  return (
    <div className="categories-container">
     

      <div className='container-Allcategories'>
      <h3 className="categories-title">Our Top Categories</h3>
      <button className="Allcategories" onClick={Allcategories} >
         All Products
        </button>
      </div>

      <div className="categories-list">
        {categories.slice(0, 8).map((category) => (
          <div key={category.category_id} className="category-wrapper">
            <div
              className="category-card"
              onClick={() => navigateToProduct(category.category_id)}
            >
              <img
                src={category.category_icons}
                alt={category.name}
                className="category-icon"
              />
            </div>
            <p className="category-name">{category.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
