import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Styles/Checkout.css";
import LoadingSpinner from "./LoadingSpinner";
import loadingGif from '../Icons/Loading.gif';

const Checkout = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
;

  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });
  
  const [processingOrder, setProcessingOrder] = useState(false);
  const [processingMessage, setProcessingMessage] = useState("");


  






  useEffect(() => {
    fetchProduct();
  }, [productId]);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        `https://ecommerce-api-m8il.vercel.app/products/${productId}`
      );
      setProduct(response.data);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem("userId");
    if (!userId) {
      navigate("/login");
      return;
    }

    const orderData = {
      user_id: userId,
      order_date: new Date().toISOString(),
      status: "Pending",
      total_amount: product.price * quantity,
    };

    try {
      setProcessingOrder(true);
      setProcessingMessage("Processing the order, please wait...");

      const response = await axios.post(
        "https://ecommerce-api-m8il.vercel.app/orders",
        orderData
      );

      if (response.status === 201) {
        const orderId = response.data.order_id;

        const orderItemData = {
          order_id: orderId,
          product_id: productId,
          quantity: quantity,
          unit_price: product.price,
        };

        setProcessingMessage("Posting your order, please wait...");

        await axios.post(
          "https://ecommerce-api-m8il.vercel.app/orderItems",
          orderItemData
        );

        const emailBody = {
          to: formData.email,
          subject: `Ecommerce Order No. ${orderId}`,
          text: `Thank you for your order!
          
Order ID: ${orderId}

Order Details:

Product: ${product.name}
Quantity: ${quantity}
Price: $${(parseFloat(product.price) * quantity).toFixed(2)}

Shipping Address:
${formData.address}`,
        };

        setProcessingMessage("Sending the order details...");

        const emailResponse = await axios.post(
          "https://api.emailsend.digihut.store/send-email",
          emailBody
        );

        if (emailResponse.status === 200) {
         setProcessingMessage("Your order was placed successfully! Check your email for details.");
          
          setTimeout(() => {
            navigate("/");
          }, 3000); // 

        } else {
          throw new Error("Failed to send email");
        }
      } else {
        throw new Error("Failed to place order");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      setProcessingOrder(false);
      alert("Failed to place order. Please try again.");
    }
  };

  if (!product) {
    return <LoadingSpinner />;
  }

  return (
    <div className="checkoutContainer">
      {processingOrder && (
        <div className="processing-overlay">
          <div className="processing-content">
            <img src={loadingGif} alt="Loading" className="loading-gif-checkout-messages" />
            <p className="Message">{processingMessage}</p>
         
           
          </div>
        </div>
      )}
      {!processingOrder && (
        <>
          <div className="productDetails-check">
            <div className="productImageContainer-check">
              <img
                src={product.product_picture}
                alt={product.name}
                className="checkoutImage"
              />
            </div>
            <div className="productInfo">
              <h2 className="productName">{product.name}</h2>
              <p className="productDescription">{product.description}</p>
              <p className="productPrice">
                Price: ${parseFloat(product.price).toFixed(2)}
              </p>
            </div>
          </div>
          <div className="checkoutForm">
            <h3>Fill in your details to complete the purchase:</h3>
            <form onSubmit={handlePlaceOrder}>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                required
              />

              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                required
              />

              <label htmlFor="address">Address:</label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleFormChange}
                rows="4"
                required
              ></textarea>

              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
                required
              />

              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={quantity}
                onChange={handleQuantityChange}
                min="1"
                required
              />

              <button className="checkout-btn" type="submit">
                Place Order
              </button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default Checkout;
