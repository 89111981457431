// NotFound.js

import React from 'react';
import '../Styles/ProductsNotFound.css';

const ProductsNotFound = () => {
  return (
    <div className="not-found">
      <h2>404 - Found</h2>
      <p>There are no products in this category</p>
    </div>
  );
};

export default ProductsNotFound;
