import React, { useState } from "react";
import { FaUser, FaHeart, FaShoppingBag, FaCog } from "react-icons/fa";
import "../Styles/Accounts.css";

const MyAccount = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("profile");

  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item);
  };

  return (
    <div className="myAccountContainer">
      <div className="sidebar">
        <div
          className={`menuItem ${selectedMenuItem === "profile" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("profile")}
        >
          <FaUser className="menuIcon" />
          Profile
        </div>
        <div
          className={`menuItem ${selectedMenuItem === "likedProducts" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("likedProducts")}
        >
          <FaHeart className="menuIcon" />
          Liked Products
        </div>
        <div
          className={`menuItem ${selectedMenuItem === "orders" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("orders")}
        >
          <FaShoppingBag className="menuIcon" />
          Orders
        </div>
        <div
          className={`menuItem ${selectedMenuItem === "settings" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("settings")}
        >
          <FaCog className="menuIcon" />
          Settings
        </div>
      </div>
      <div className="content">
        {selectedMenuItem === "profile" && (
          <div className="profileContent">
            {/* Profile Content */}
            <h2>Profile</h2>
            <p>User profile details go here...</p>
          </div>
        )}
        {selectedMenuItem === "likedProducts" && (
          <div className="likedProductsContent">
            {/* Liked Products Content */}
            <h2>Liked Products</h2>
            <p>List of liked products...</p>
          </div>
        )}
        {selectedMenuItem === "orders" && (
          <div className="ordersContent">
            {/* Orders Content */}
            <h2>Orders</h2>
            <p>List of orders...</p>
          </div>
        )}
        {selectedMenuItem === "settings" && (
          <div className="settingsContent">
            {/* Settings Content */}
            <h2>Settings</h2>
            <p>User settings...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
