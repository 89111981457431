import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Skeleton from './Skelton';
import { FaShoppingCart, FaHeart } from 'react-icons/fa';
import loadingGif from '../Icons/Loading.gif'; // Replace with the path to your loading GIF
import '../Styles/Products.css';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleProducts, setVisibleProducts] = useState(4);
  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://ecommerce-api-m8il.vercel.app/products');
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  const handleBuyNow = (productId) => {
    navigate(`/SingleProduct/${productId}`);
  };

  const AllProducts = () => {
    navigate('/AllProducts');
  };

  const handleLoadMore = () => {
    setLoadingMore(true);
    setTimeout(() => {
      setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 4);
      setLoadingMore(false);
    }, 1000); // Simulate loading time
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <div className="product-container">
      <div className='container-head'>
      <h3 className="title">Top Products</h3>
      <button className="AllProducts" onClick={AllProducts} >
         All Products
        </button>
      </div>
     
      <div className="product-grid">
        {products.slice(0, visibleProducts).map((product) => (
          <div key={product.product_id} className="product-card">
            <div className="product-image-container">
              <img src={product.product_picture} alt={product.name} className="product-image" />
              <button className="like-button"><FaHeart size={20} /></button>
            </div>
            <div className="product-info">
              <p className="product-name">{product.name}</p>
              <p className="product-description">{product.description}</p>
              <p className="product-price">${parseFloat(product.price).toFixed(2)}</p>
              <button onClick={() => handleBuyNow(product.product_id)} className="buy-now-button">
                <FaShoppingCart size={20} /> Buy Now
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className='loadmorebox'>

      {visibleProducts < products.length && (
        <button className="load-more-button" onClick={handleLoadMore} disabled={loadingMore}>
          {loadingMore ? <img src={loadingGif} alt="Loading" className="loading-gif" /> : "Load More"}
        </button>
      )}
      </div>
    
    </div>
  );
};

export default Products;
