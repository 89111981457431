// src/pages/Account.js
import React from 'react';
import MyAccount from '../components/Myaccount';

const Account = () => {

    const styles = {
        container: {
           
           
            padding: '10px',
           
            borderRadius: '10px',
           
        },

    }


    return <div style={styles.container}>

        <MyAccount></MyAccount>
        
    </div>;
};

export default Account;
