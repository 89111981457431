import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Skeleton from './Skelton';
import { FaTh, FaList, FaShoppingCart, FaHeart } from 'react-icons/fa';

const AllProducts = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [minPrice, setMinPrice] = useState('');
    const [loading, setLoading] = useState(true)
    const [maxPrice, setMaxPrice] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [viewMode, setViewMode] = useState('grid');
    const [sortOption, setSortOption] = useState('topSale');
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
        fetchCategories();
    }, []);

    useEffect(() => {
        filterProducts();
    }, [products, minPrice, maxPrice, selectedCategory, sortOption]);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://ecommerce-api-m8il.vercel.app/products');
            setProducts(response.data);
            setFilteredProducts(response.data);
           setLoading(false);
        } catch (error) {
            console.error('Error fetching products:', error);
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://ecommerce-api-m8il.vercel.app/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const filterProducts = () => {
        let filtered = products;

        if (minPrice) {
            filtered = filtered.filter(product => parseFloat(product.price) >= parseFloat(minPrice));
        }

        if (maxPrice) {
            filtered = filtered.filter(product => parseFloat(product.price) <= parseFloat(maxPrice));
        }

        if (selectedCategory) {
            filtered = filtered.filter(product => product.category_id === selectedCategory);
        }

        if (sortOption === 'topSale') {
            filtered = filtered.sort((a, b) => b.sales - a.sales);
        } else if (sortOption === 'priceLowToHigh') {
            filtered = filtered.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
        } else if (sortOption === 'priceHighToLow') {
            filtered = filtered.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
        }

        setFilteredProducts(filtered);
    };

    const handleBuyNow = (productId) => {
        navigate(`/SingleProduct/${productId}`);
        console.log(productId);
    };


if (loading) {


    return <Skeleton></Skeleton>
};


// add to fav function


// const handleAddToFavorites = async () => {

// const userId = 123;
// const productId = 123;


//     try {
//         const response = await axios.post('https://your-api-endpoint.com/favorite', {
//             product_id: productId,
//             user_id: userId,
//         });
//         console.log('Product added to favorites:', response.data);
//     } catch (error) {
//         console.error('Error adding product to favorites:', error);
//     }
// };

// End the add to fav function



    return (
        <div style={styles.container}>
            <div style={styles.sidebar}>
                <h3>Filter by Price</h3>
                <input
                    type="number"
                    placeholder="Min Price"
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                    style={styles.input}
                />
                <input
                    type="number"
                    placeholder="Max Price"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                    style={styles.input}
                />
                <h3>Filter by Category</h3>
                <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} style={styles.select}>
                    <option value="">All Categories</option>
                    {categories.map(category => (
                        <option key={category.category_id} value={category.category_id}>{category.name}</option>
                    ))}
                </select>
            </div>
            <div style={styles.content}>
                <div style={styles.header}>
                    <div style={styles.gridbox}>
                        <button onClick={() => setViewMode('grid')} style={viewMode === 'grid' ? styles.activeButton : styles.button}><FaTh /></button>
                        <button onClick={() => setViewMode('list')} style={viewMode === 'list' ? styles.activeButton : styles.button}><FaList /></button>
                    </div>
                    <select value={sortOption} onChange={(e) => setSortOption(e.target.value)} style={styles.select}>
                        <option value="topSale">Top Sale</option>
                        <option value="priceLowToHigh">Price: Low to High</option>
                        <option value="priceHighToLow">Price: High to Low</option>
                    </select>
                </div>
                <div style={viewMode === 'grid' ? styles.grid : styles.list}>
                    {filteredProducts.map(product => (
                        <ProductCard key={product.product_id} product={product} handleBuyNow={handleBuyNow} viewMode={viewMode} />
                    ))}
                </div>
            </div>
        </div>
    );
};
const handleAddToFavorites = () =>{
    alert('Like button clicked');
}
const ProductCard = ({ product, handleBuyNow, viewMode }) => (
    <div style={viewMode === 'grid' ? styles.productCardGrid : styles.productCardList}>
        <div style={styles.productImageContainer}>
            <img src={product.product_picture} alt={product.name} style={styles.productImage} />
            <button  onClick={() => handleAddToFavorites(product.product_id,)} style={styles.likeButton}><FaHeart size={20} /></button>
        </div>
        <div style={styles.productInfo}>
            <p style={styles.productName}>{product.name}</p>
            <p style={styles.productDescription}>{product.description}</p>
            <p style={styles.productPrice}>${parseFloat(product.price).toFixed(2)}</p>
            <button onClick={() => handleBuyNow(product.product_id)} style={styles.buyNowButton}>
                <FaShoppingCart size={20} /> Buy Now
            </button>
        </div>
    </div>
);

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '20px',
        backgroundColor: '#f8f8f8',
        margin: '10px',
        borderRadius: '8px',
    },
    sidebar: {
        flex: '1 1 200px',
        marginRight: '20px',
        backgroundColor: '#f85606',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    content: {
        flex: '3 1 800px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        backgroundColor: '#f85606',
        padding: 10,
        borderRadius: '8px',
    },
    button: {
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '8px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    activeButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        border: '1px solid #007bff',
        borderRadius: '4px',
        padding: '8px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    select: {
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        width: '100%',
    },
    gridbox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        
        width: '10%',
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '8px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    productCardGrid: {
        backgroundColor: '#ccd8d8',
        borderRadius: '0px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    productCardList: {
        backgroundColor: '#000',
        borderRadius: '0px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    productImageContainer: {
        position: 'relative',
    },
    productImage: {
        width: '100%',
        height: 'auto',
        display: 'block',
    },
    productInfo: {
        padding: '10px',
    },
    productName: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        margin: '0',
    },
    productDescription: {
        fontSize: '1em',
        color: '#666666',
        margin: '5px 0',
    },
    productPrice: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        color: '#007bff',
        margin: '0',
    },
    buyNowButton: {
        backgroundColor: '#007bff',
        color: '#ffffff',
        border: 'none',
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        width: '100%',
    },
    likeButton: {
        backgroundColor: '#dc3545',
        color: '#ffffff',
        border: 'none',
        padding: '8px',
        borderRadius: '100%',
        cursor: 'pointer',
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent:  'center',
    },
};

export default AllProducts;
